<template>
  <div class="container-login">
    <div class="mx-auto mt-1" style="max-width: 350px">
      <h2>Modifique su contraseña</h2>
      <hr />
      <b-form-group
        id="input-group-current_password"
        label="Contraseña actual"
        label-for="input-current_password"
      >
        <div class="d-flex">
          <div class="d-blok w-100">
            <b-form-input
              id="input-current_password"
              :type="!current_password_visibility ? 'password' : 'text'"
              placeholder="Contraseña actual"
              @keyup.enter="changePassword"
              v-model="$v.user_password.current_password.$model"
              :class="{ 'my-2': !current_password_visibility }"
              :state="validateState('current_password')"
              aria-describedby="input-current_password-name-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="input-current_password-name-feedback"
              >Este campo es obligatorio.</b-form-invalid-feedback
            >
          </div>
          <b-button
            class="password-visibility"
            @click="
              () => (current_password_visibility = !current_password_visibility)
            "
            ><b-icon v-if="current_password_visibility" icon="eye"></b-icon
            ><b-icon v-else icon="eye-slash"></b-icon
          ></b-button>
        </div>
      </b-form-group>

      <b-form-group
        id="input-group-password"
        label="Nueva contraseña"
        label-for="input-password"
      >
        <div class="d-flex">
          <div class="d-blok w-100">
            <b-form-input
              id="input-password"
              v-model="$v.user_password.password.$model"
              :type="!new_password_visibility ? 'password' : 'text'"
              :state="validateState('password')"
              placeholder="Nueva contraseña"
              @keyup.enter="changePassword"
              :class="{ 'my-2': !new_password_visibility }"
              aria-describedby="input-password-name-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="input-password-name-feedback"
              ><div class="d-block text-left">
                La contraseña de estar compuesta al menos por:
                <div v-if="!/[a-z]/.test(user_password.password)" class="ml-4">
                  - Al menos una letra en minúsculas.
                </div>
                <div v-if="!/[A-Z]/.test(user_password.password)" class="ml-4">
                  - Al menos una letra en mayúsculas.
                </div>
                <div v-if="!/[0-9]/.test(user_password.password)" class="ml-4">
                  - Al menos un número.
                </div>
                <div
                  v-if="!/[.#?!@$%^&*-]/.test(user_password.password)"
                  class="ml-4"
                >
                  - Al menos un simbolo.
                </div>
                <div
                  v-if="
                    $v.user_password.password.$params.minLength.min >
                    user_password.password.length
                  "
                  class="ml-4"
                >
                  - Debe tener
                  {{ $v.user_password.password.$params.minLength.min }}
                  carácteres como mínimo.
                </div>
              </div></b-form-invalid-feedback
            >
          </div>
          <b-button
            class="password-visibility"
            @click="() => (new_password_visibility = !new_password_visibility)"
            ><b-icon v-if="new_password_visibility" icon="eye"></b-icon
            ><b-icon v-else icon="eye-slash"></b-icon
          ></b-button>
        </div>
      </b-form-group>
      <b-form-group
        id="input-group-confirm_password"
        label="Confirmar contraseña"
        label-for="input-confirm_password"
      >
        <div class="d-flex">
          <div class="d-blok w-100">
            <b-form-input
              id="input-confirm_password"
              :type="!confirm_password_visibility ? 'password' : 'text'"
              v-model="$v.user_password.confirm_password.$model"
              :class="{ 'my-2': !confirm_password_visibility }"
              :state="validateState('confirm_password')"
              placeholder="Confirmar contraseña"
              @keyup.enter="changePassword"
              aria-describedby="input-confirm_password-name-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="input-confirm_password-name-feedback"
              >Las contraseñas no son iguales.</b-form-invalid-feedback
            >
          </div>
          <b-button
            class="password-visibility"
            @click="
              () => (confirm_password_visibility = !confirm_password_visibility)
            "
            ><b-icon v-if="confirm_password_visibility" icon="eye"></b-icon
            ><b-icon v-else icon="eye-slash"></b-icon
          ></b-button>
        </div>
      </b-form-group>
      <b-button @click="changePassword" variant="primary"
        >Cambiar contraseña</b-button
      >
      <hr class="ml-2" />
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { toast } from "@/utils/utils";
import { mapGetters } from "vuex";
import { required, minLength, sameAs } from "vuelidate/lib/validators";
export default {
  name: "UpdatePassword",
  mixins: [validationMixin],

  data() {
    return {
      user_password: {
        current_password: "",
        password: "",
        confirm_password: "",
      },
      current_password_visibility: false,
      new_password_visibility: false,
      confirm_password_visibility: false,
    };
  },
  validations: {
    user_password: {
      current_password: {
        required,
      },
      password: {
        required,
        minLength: minLength(8),
        containsUppercase: function (value) {
          return /[A-Z]/.test(value);
        },
        containsLowercase: function (value) {
          return /[a-z]/.test(value);
        },
        containsNumber: function (value) {
          return /[0-9]/.test(value);
        },
        containsSpecial: function (value) {
          return /[.#?!@$%^&*-]/.test(value);
        },
      },
      confirm_password: { required, sameAsPassword: sameAs("password") },
    },
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.user_password[name];
      return $dirty ? !$error : null;
    },
    changePassword() {
      this.$v.user_password.$touch();
      if (this.$v.user_password.$anyError) {
        return;
      }
      const payload = {
        old_password: this.user_password.current_password,
        new_password: this.user_password.password,
        repeated_password: this.user_password.confirm_password,
      };
      this.$restful
        .Put(`/authentication/password/`, payload)
        .then((response) => {
          if (response) {
            this.user.has_changed_password = true;
            this.$store.commit("setUser", this.user);
            toast("Contraseña actualizada con éxito.");
          }
        })
        .catch(() => {
          return this.$swal({
            text: "La contraseña actual es incorrecta.",
            type: "warning",
          });
        });
    },
  },
  created() {},
};
</script>

<style scoped>
.container-login {
  position: relative;
  margin: auto;
  width: 100%;
}
.div-href {
  color: #007bff;
}
.div-href:hover {
  color: #0057b1 !important;
  text-decoration: underline;
  cursor: pointer;
}
.password-visibility {
  height: 2.35em;
  margin-top: 8px;
  margin-left: 1%;
}
</style>